/* 
    Created on : 20 dÃ©c. 2018, 21:42:58
    Author     : CÃ©dric Courbot
*/

@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700');
@import "~bootstrap/scss/bootstrap";
@import "~font-awesome/scss/font-awesome";
@import "~animate.css";
@import "~owl.carousel/src/scss/owl.carousel";
@import "~slick-carousel/slick/slick.scss";
@import "~compass-mixins";
@import "~magnific-popup/dist/magnific-popup.css";

@mixin transition($property) {
    -webkit-transition: $property;
    -moz-transition: $property;
    -o-transition: $property;
    -ms-transition: $property;
    transition: $property;
}
@mixin transform($property) {
    -webkit-transform: $property;
    -moz-transform: $property;
    -o-transform: $property;
    -ms-transform: $property;
    transform: $property;
}
@mixin transform-origin($property) {
    -webkit-transform-origin: $property;
    -moz-transform-origin: $property;
    -o-transform-origin: $property;
    -ms-transform-origin: $property;
    transform-origin: $property;
}
@mixin box-shadow($property) {
    -webkit-box-shadow: $property;
    -moz-box-shadow: $property;
    -o-box-shadow: $property;
    -ms-box-shadow: $property;
    box-shadow: $property;
}

$grey-color: #111111;
$grey2-color: #555555;
$yellow-color: #fbc02d;
$html-color: #505050;

/* -----------------------------------
    GLOBAL CSS
----------------------------------- */
html {
    height: 100%;
    font-size: 15px;
    color: $html-color !important;
    font-family: 'Poppins', sans-serif !important;
    vertical-align: baseline;
    line-height: 25px;
    font-weight: 400;
    background: #ffffff;
}

table {
    width: 100%;
}
body {
    @extend html;
}
.sec-spacer {
    padding: 100px 0;
}
.pb-50 {
    padding-bottom: 50px;
}
p {
    margin: 0 0 26px;
}
h1 {
    font-size: 40px;
    margin: 0 0 26px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    color: $grey-color;
}
h2 {
    font-size: 36px;
    margin: 0 0 26px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    color: $grey-color;
}
h3 {
    font-size: 30px;
    margin: 0 0 26px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    color: $grey-color;
}
h4 {
    font-size: 24px;
    margin: 0 0 26px;
    font-weight: 500;
    text-transform: capitalize;
    font-family: 'Poppins', sans-serif;
    color: $grey-color;
}
h5 {
    font-size: 20px;
    margin: 0 0 26px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    color: $grey-color;
}
h6 {
    font-size: 16px;
    margin: 0 0 26px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    color: $grey-color;
}
a {
    @include transition(all 0.4s ease 0s);
    text-decoration: none;
    outline: 0px solid;
    &:focus {
        outline: 0px solid;
        text-decoration: none;
    }
    &:active {
        color: $yellow-color;
        outline: 0 none;
    }
    &:hover {
        outline: 0 none;
        color: $yellow-color;
        text-decoration: none;
    }
}
img {
    max-width: 100%;
    height: auto;
    @include transition(all 0.8s ease-in-out 0s);
}
::-moz-selection {
    background: #fbc02d;
    text-shadow: none;
}
::selection {
    background: #fbc02d;
    text-shadow: none;
    color: #ffffff;
}
::-moz-placeholder {
    color: #505050;
    opacity: 1 !important;
}
::-webkit-placeholder {
    color: #505050;
    opacity: 1 !important;
}
:-webkit-input-placeholder {
    color: #505050;
    opacity: 1 !important;
}
:-ms-input-placeholder {
    color: #505050;
    opacity: 1 !important;
}
:-moz-placeholder {
    color: #505050;
    opacity: 1 !important;
}
:-moz-input-placeholder {
    color: #505050;
    opacity: 1 !important;
}
ul {
    list-style: outside none none;
    margin: 0;
    padding: 0;
}
.overly-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.7;
    top: 0;
    left: 0;
}

.sec-spacer {
    padding: 100px 0;
}

.primary-btn  {
    background: $yellow-color;
    color: $grey-color;
    padding: 8px 25px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    display: inline-block;
    font-family: 'Poppins', sans-serif;
    @include transition(0.4s);
    &:hover {
        color: #ffffff !important;
    }
}

.title-bg {
    position: relative;
    font-size: 24px;
    color: $grey-color;
    display: inline-block;
    text-transform: capitalize;
    margin-bottom: 44px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    line-height: 17px;
    padding-bottom: 10px;
    &:after {
        background: $yellow-color;
        width: 50%;
        height: 4px;
        left: 0;
        position: absolute;
        content: "";
        bottom: -14px;
    }
    &:before {
        background: #999999;
        width: 100%;
        height: 4px;
        left: 0;
        position: absolute;
        content: "";
        bottom: -14px;
    }
}
/* -----------------------------------
HEADER CSS
----------------------------------- */
.header-top-area {
    padding: 5px 0;
    font-size: 12px;
    line-height: 28px;
    background: $yellow-color;
    .header-top-left {
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            li {
                float: left;
                color: $grey-color;
                line-height: 32px !important;
                @include transition(all 0.4s ease 0s);
                &:last-child {
                    margin-left: 30px;
                    img {
                        margin-right: 5px;
                        margin-top: -4px;
                        width: 22px;
                        border-radius: 2px;
                    }
                    i {
                        position: relative;
                        top: 1px;
                    }
                    ul {
                        margin-left: -24px;
                        li {
                            img {
                                margin-right: 5px;
                            }
                        }
                    }
                }
                &:hover {
                    ul {
                        opacity: 1;
                        overflow: visible;
                        visibility: visible;
                        background: $yellow-color;
                        li {
                            margin-left: 24px;
                        }
                    }
                }
                a {
                    transition: all 0.4s ease 0s;
                    color: $grey-color;
                    font-family: 'Poppins', sans-serif;
                    font-size: 14px;
                    font-weight: 400;
                    &:hover {
                        color: $html-color;
                    }
                    i {
                        font-weight: 400;
                    }
                }
                ul {
                    overflow: hidden;
                    opacity: 0;
                    visibility: hidden;
                    margin: 0;
                    padding: 5px 0;
                    position: absolute;
                    width: 180px;
                    z-index: 12;
                    li {
                        line-height: 20px !important;
                        a {
                            font-weight: 400;
                        }
                    }
                }
            }
        }
    }
    .social-media-area {
        ul {
            text-align: right;
            li {
                display: inline-block;
                a {
                    display: block;
                    color: $grey-color;
                    @include transition(all 0.4s ease 0s);
                    text-decoration: none;
                    text-align: center;
                    font-size: 13px;
                    margin-right: 15px;
                    &:hover {
                        color: #644d12;
                    }
                    i {
                        text-align: center;
                        display: inline-block;
                    }
                }
            }
        }
        .sign {
            font-weight: 400;
            a {
                margin-right: 6px;
                color: $grey-color;
                font-size: 14px;
                &:hover {
                    color: $html-color;
                }
            }
        }
        .log {
            @extend .sign;
            margin-left: 15px;
        }
    }
}

.header-middle-area {
    background: $grey-color;
    .rs-menu {
        padding-right: 35px;
        position: relative;
        float: left;
        clear: both;
        width: 100%;
        z-index: 11;
        p {
            border: 0;
            margin: 0;
            padding: 0;
        }
        div {
            border: 0;
            margin: 0;
            padding: 0;
        }
        i {
            border: 0;
            margin: 0;
            padding: 0;
        }
        a {
            color:#4a4a4a;
            display: block;
            padding: 16px;
            border: 0;
            margin: 0;
            -webkit-tap-highlight-color:rgba(0,0,0,0);
            &.active {
                color: $yellow-color !important;
            }
        }
        li {
            border: 0;
            margin: 0;
            padding: 0;
            display: block;
            font-size: 14px;
            vertical-align: top;
            position: relative;
        }
        ul {
            display:block;
            z-index: 17;
            border: 0;
            margin: 0;
            padding: 0;
            text-align: right;
            ul {
                position: absolute;
                top: 100%;
                width: 100%;
                display: none;
                z-index: 999;
                border-top: 4px solid $yellow-color;
                li {
                    border-bottom: 1px solid #333 !important;
                    background: $grey-color;
                    &:last-child {
                        border-bottom: 0px solid #f0ad05 !important;
                    }
                    a {
                        color: #ffffff;
                    }
                    &:hover {
                        &>a {
                            color: $yellow-color !important;
                        }
                    }
                }
                ul {
                    &.visible {
                        display:block;
                        z-index: 17;
                    }
                }
                a {
                    font-size:14px;	
                    text-align:left;
                }
            }
            &.sub-menu {
                &>li {
                    &.active {
                        &>a {
                            color: $yellow-color !important;
                        } 
                    }
                }
            }
            li {
                a {
                    &.hover {
                        color: $yellow-color !important;
                    }
                }
            }
            &>li {
                &.active {
                    &>a {
                        color: $yellow-color !important;
                    }
                }
            }
        }
        &>ul {
            &>li {
                &>a {
                    padding: 28px 16px;
                }
            }
        }
        .sub-menu {
            @include box-shadow(0px 4px 13px -3px #232323);
            li {
                a {
                    padding: 10px 14px;
                }
            }
        }
        .nav-menu {
            &>.current_page_item {
                &>a {
                    color: $yellow-color !important;
                }
            }
            &>li {
                &>a {
                    font-size: 16px;
                    text-transform: capitalize;
                    font-weight: 400;
                    color: #ffffff;
                    &:hover {
                        color: $yellow-color !important;
                    }
                }
            }
            ul {
                @include transition(all .3s ease-in-out);
                .current-menu-item {
                    a {
                        color: $yellow-color !important;
                    }
                }
                .current_page_item {
                    a {
                        color: $yellow-color !important;
                    }
                }
            }
        }
    }
    .main-menu {
        position: relative;
    }
    .container {
        position: relative;
    }
    .logo {
        position: relative;
        z-index: 999;
        height: 77px;
        padding-top: 10px;
        @include transition(0.4s);
        img {
            @include transition(0.4s);
        }
    }
}

.menu-sticky {
    &.sticky {
        background-color: rgba(0, 0, 0, 0.95);
        position: fixed !important;
        z-index: 9999;
        width: 100%;
        top: 0;
        .logo {
            img {
                max-width: 75px;
                @include transition(0.4s);
            }
        }
    }
}

/* ------------------------------------
   Home Slider Area Start Here 
---------------------------------------*/
.slider-main {
    position: relative;
    overflow: hidden;
    .item {
        position: relative;
        &:after {
            background: rgba(0, 0, 0, 0.7);
            content: "";
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
        img {
            width: 100%;
        }
    }
    .dsc {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        overflow: hidden;
        @include transform(translateY(-50%));
        z-index: 99;
        h1 {
            color: #ffffff;
            font-size: 60px;
            letter-spacing: 1px;
            text-transform: capitalize;
            margin-bottom: 4px;
            line-height: 70px;
            font-weight: 600;
        }
        h2 {
            color: #ffffff;
            font-size: 60px;
            letter-spacing: 1px;
            text-transform: capitalize;
            margin-bottom: 4px;
            line-height: 70px;
            font-weight: 600;
        }
        .slider-image {
            img {
                max-width: 85% !important;
            }
        }
        .btn-slider {
            a {
                background: $yellow-color;
                padding: 11px 40px;
                color: $grey-color;
                font-size: 14px;
                text-transform: uppercase;
                border-radius: 2px;
                font-weight: 500;
                margin-bottom: 14px;
                display: inline-block;
                border: 2px solid $yellow-color;
                &:hover {
                    border: 2px solid $yellow-color;
                    background: transparent;
                    color: #fff;
                }
            }
            .btn1 {
                border: 2px solid $yellow-color;
                background: transparent;
                color: #fff;
                padding: 11px 45px;
                margin-left: 0;
                margin-right: 12px;
                &:hover {
                    background: $yellow-color;
                }
            }
            .btn2 {
                margin-left: 12px;
                margin-right: 0;
            }
        }
    }
    .owl-dots {
        position: absolute;
        bottom: 50px;
        right: 7%;
        z-index: 11;
        .owl-dot {
            width: 15px;
            height: 15px;
            background: $yellow-color;
            border-radius: 50%;
            margin: 0 5px;
            display: inline-block;
            &.active {
                background: #fff;
            }
        }
    }
    .slider-text {
        margin-top: 0;
        h1 {
            font-size: 60px;
            span {
                color: $yellow-color;
                margin: 0 20px;
            }
        }
        .CountDownTimer {
            max-width: 550px;
            margin: 40px auto 35px;
            .time_circles {
                position: relative;
                width: 100%;
                height: 100%;
                left: 0;
                &>div {
                    position: absolute;
                    text-align: left;
                    &>h4 {
                        margin: 0;
                        padding: 0;
                        text-align: center;
                        text-transform: uppercase;
                        line-height: 1;
                    }
                    &>span {
                        margin: 0;
                        padding: 0;
                        display: block;
                        width: 100%;
                        text-align: center;
                        line-height: 1;
                    }
                    span {
                        font-weight: 400;
                        font-size: 36px !important;
                        color: $yellow-color;
                        font-family: 'Poppins', sans-serif;
                        margin-top: -3px;
                        margin-bottom: 3px;
                    }
                    h4 {
                        font-family: 'Poppins', sans-serif;
                        color: #fff;
                        font-weight: 400;
                        text-transform: capitalize;
                        font-size: 12px !important;
                    }
                }
            }
        }
    }
}

/* ------------------------------------
    Upcoming Section Start Here 
---------------------------------------*/
.upcoming-section {
    background: url(../images/upcoming/background.jpg) no-repeat center top;
    background-size: cover;
    padding: 93px 0 98px;
    margin-top: -1px;
    color: #fff;
    text-align: center;
    .container {
        max-width: 900px;
        margin: 0 auto;
        h2 {
            text-align: center;
            color: #fff;
            margin-bottom: 40px;
            font-weight: 600;
            letter-spacing: 1px;
        }
        h4 {
            color: #fff;
            font-size: 15px;
            font-weight: 400;
            margin: 14px 0 0;
            text-transform: uppercase;
        }
        h6 {
            font-size: 13px;
            margin-top: 5px;
            margin-bottom: 10px;
            color: #fff;
        }
        .view-score {
            a {
                color: $yellow-color;
                &:hover {
                    color: #fff;
                }
            }
        }
        span {
            display: block;
            text-transform: uppercase;
            font-size: 15px;
            margin-right: 0;
            &.date {
                padding-top: 10px;
                font-weight: 500;
                font-family: 'Poppins', sans-serif;
                font-size: 18px;
            }
        }
        .last {
            img {
                margin-top: 20px;
            }
        }
        .vs {
            color: $yellow-color;
            font-size: 25px;
            font-weight: 600;
            padding: 50px 0;
        }
        .owl-carousel {
            .owl-item {
                img {
                    max-width: 100%;
                    margin: 0 auto;
                    width: auto;
                }
            }
        }
        .owl-nav {
            i {
                &:hover {
                    color: $yellow-color;
                }
            }
        }
    }
    
}

/* ------------------------------------
    Scroll To Css Start Here 
---------------------------------------*/
#return-to-top {
    position: fixed;
    bottom: 30px;
    right: 20px;
    background: $yellow-color;
    width: 50px;
    height: 50px;
    display: block;
    text-decoration: none;
    display: none;
    @include transition(all 0.3s ease);
    cursor: pointer;
    text-align: center;
    overflow: hidden;
    z-index: 999;
    &:before {
        @include transition(all 0.3s cubic-bezier(0.37, 0.31, 0.2, 0.85) 0s);
    }
    &:hover {
        &::before {
            top: -60px;;
        }
    }
    &::before {
        bottom: 0;
        content: "\f106";
        font-family: FontAwesome;
        font-size: 20px;
        left: 0;
        line-height: 60px;
        margin: auto;
        position: absolute;
        right: 0;
        text-align: center;
        top: -5px;
        color: #ffffff;
    }
    &:hover {
        background: $grey-color;
        span {
            top: -6px;
        }
    }
    span {
        bottom: 0;
        left: 0;
        line-height: 60px;
        margin: auto;
        position: absolute;
        right: 0;
        text-align: center;
        top: 60px;
        color: #ffffff;
        @include transition(all 0.3s cubic-bezier(0.37, 0.31, 0.2, 0.85) 0s);
    }
}

/* ------------------------------------
    Latest News Area Start Here 
---------------------------------------*/

.all-news-area {
    .latest-news-slider {
        .slick-arrow {
            position: absolute;
            z-index: 11;
            bottom: 0;
            right: -106px;
            height: 36px;
            width: 36px;
            font-size: 0;
            border: 0;
            background-color: $yellow-color;
            color: #ffffff;
            cursor: pointer;
            outline: 0;
            border-radius: 50%;
            @include transition(all 0.4s ease 0s);
            &:hover {
                background-color: $grey-color;
                color: #fff;
            }
            &:after {
                font-family: FontAwesome;
                position: absolute;
                top: 50%;
                left: 50%;
                @include transform(translateX(-50%) translateY(-50%));
                font-size: 20px;
            }
            &.slick-prev {
                &:after {
                    content: "\f104";
                }
            }
            &.slick-next {
                right: -158px;
                &:after {
                    content: "\f105";
                }
            }
        }
        .news-normal-block {
            height: 600px;
            padding: 15px;
            border: 1px solid rgba(34, 34, 34, 0.1);
            @include transition(all 0.4s ease 0s);
            &:hover {
                @include box-shadow(0 10px 40px rgba(0, 0, 0, 0.1));
            }
            .news-img {
                margin-bottom: 20px;
                img {
                    /*width: 100%;*/
                    height: 380px;
                }
            }
            .news-title {
                font-size: 18px;
                margin-bottom: 12px;
                a {
                    color: #212121;
                    &:hover {
                        color: $yellow-color;
                    }
                    &:focus {
                        color: $yellow-color;
                    }
                }
            }
            .news-desc {
                p {
                    margin-bottom: 20px;
                }
            }
            .news-btn {
                position: absolute;
                bottom: 0;
                margin-bottom: 10px;
                a {
                    display: inline-block;
                    margin-left: auto;
                    padding: 4px 16px;
                    font-size: 13px;
                    font-weight: 500;
                    text-decoration: none;
                    background-color: $grey-color;
                    color: #fff;
                    &:hover {
                        background-color: $yellow-color;
                    }
                }
            }
            .news-list-item {
                padding: 15px;
                border: 1px solid rgba(34, 34, 34, 0.1);
                @include transition(all 0.4s ease 0s);
                display: -ms-flexbox;
                display: flex;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                &:hover {
                    @include box-shadow(0 10px 40px rgba(0, 0, 0, 0.1));
                }
                + .news-list-item {
                    margin-top: 23px;
                }
                .news-img {
                    flex: 0 0 33.333333%;
                    max-width: 33.333333%;
                    padding-right: 15px;
                }
            }
        }
    }
    .latest-news-nav {
        img {
            max-height: 150px;
            margin-left: auto;
            margin-right: auto;
        }
        .slick-track {
            width: 100% !important;
            @include transform(translate3d(0px, 0px, 0px) !important);
        }
        .slick-slide {
            width: calc(100% - 2px) !important;
            margin-bottom: 12px;
            cursor: pointer;
            background-color: #fff;
            border: 1px solid rgba(34, 34, 34, 0.1);
            padding: 8px;
            @include transition(all 0.4s ease 0s);
            outline: none !important;
            &.slick-current {
                background-color: $yellow-color;
                border-color: $yellow-color;
                img {
                    opacity: 0.8;
                }
            }
            &:hover {
                background-color: $yellow-color;
                border-color: $yellow-color;
                img {
                    opacity: 0.8;
                }
            }
        }
    }
}

/* ------------------------------------
    Last Match Results
---------------------------------------*/
.today-match-teams {
    background: url(../images/today-match/bg.jpg);
    background-size: cover;
    position: relative;
    .today-results-table {
        position: relative;
        z-index: 11;
        tr {
            border-top: 1px solid $grey2-color;
            &:first-child {
                position: relative;
                td {
                    color: $yellow-color;
                    font-size: 14px;
                    position: relative;
                    z-index: 11;
                    &.tb-title {
                        color: $yellow-color;
                        font-size: 14px;
                        position: relative;
                        z-index: 11;
                    }
                }
                &:after {
                    position: absolute;
                    content: "";
                    width: 100%;
                    height: 41px;
                    top: 0;
                    left: 0;
                    background: $grey-color;
                    opacity: 0.6;
                    overflow: hidden;
                }
            }
            td {
                font-family: 'Poppins', sans-serif;
                font-size: 12px;
                color: #cccccc;
                padding: 9px 15px;
                font-weight: 600;
                &.tb-title {
                    text-align: left;
                    color: #ffffff;
                    &.first-child {
                        color: $yellow-color;
                    }
                }
            }
        }
        .view-score {
            border-top: 1px solid $grey2-color;
            a {
                color: $yellow-color;
                display: block;
                height: 56px;
                line-height: 56px;
                &:hover {
                    color: #ffffff;
                }
            }
        }
    }
    .title-head {
        position: relative;
        z-index: 11;
        padding: 25px 16px 20px;
        h4 {
            color: #ffffff;
            font-size: 18px;
            text-transform: capitalize;
            margin: 0 0 4px;
            font-weight: 500;
        }
        span {
            color: #cccccc;
            font-size: 12px;
            text-transform: uppercase;
            font-weight: 600;
        }
    }
    .today-score {
        position: relative;
        z-index: 11;
        overflow: hidden;
        padding: 0 4px 12px;
        .today-match-team {
            float: left;
            width: 110px;
            h4 {
                margin: 8px 0 0;
                color: #ffffff;
                font-size: 14px;
                text-transform: uppercase;
            }
            span {
                font-size: 12px;
                color: #cccccc;
                text-transform: uppercase;
            }
            img {
                border-radius: 5px;
            }
        }
        .today-final-score {
            float: left;
            width: 120px;
            font-weight: 600;
            color: #ffffff;
            font-size: 24px;
            span {
                color: $yellow-color;
            }
            h4 {
                margin: 16px 0 0;
                color: $yellow-color;
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 15px;
                text-transform: uppercase;
            }
        }
    }
}

/* ------------------------------------
    Breadcrumbs Start here 
---------------------------------------*/
.rs-breadcrumbs {
    position: relative;
    overflow: hidden;
    &:after {
        position: absolute;
        height: 100%;
        width: 100%;
        content: "";
        top: 0;
        left: 0;
        background: linear-gradient($grey-color 40%, $yellow-color);
        opacity: 0.8;
    }
    img {
        width: 100%;
    }
    .breadcrumbs-inner {
        position: absolute;
        width: 100%;
        text-align: center;
        z-index: 1;
        top: 30%;
        @include transform(-50%);
        max-width: 100%;
        .page-title {
            margin-top: 6px;
            font-size: 36px;
            margin-bottom: 10px;
            letter-spacing: 0.5px;
            color: #ffffff;
            font-weight: 600;
        }
        h5 {
            color: #ffffff;
            letter-spacing: 0.5px;
        }
        ul {
            display: inline-block;
            padding: 4px 20px;
            li {
                color: $yellow-color;
                display: inline-block;
                font-size: 15px;
                font-weight: 600;
                a {
                    position: relative;
                    padding-right: 30px;
                    @include transition(0.3s);
                    color: #ffffff;
                    &:hover {
                        color: $yellow-color;
                    }
                    &:after {
                        content: "\f0c1";
                        font-family: "FontAwesome";
                        font-size: 15px;
                        color: #fff;
                        font-weight: 700;
                        display: block;
                        bottom: -4px;
                        right: 5px;
                        text-align: center;
                        position: absolute;
                    }
                }
            }
        }
    }
}

/* ------------------------------------
    Team page page css Start Here
------------------------------------ */
.team-page {
    h3 {
        font-family: 'Poppins', sans-serif;
        font-size: 20px;
        text-transform: uppercase;
    }
    .match-summary {
        background: #f2f2f2;
        table {
            tr {
                border-top: 1px solid #e0e0e0;
                border-bottom: 1px solid #ebebeb;
                color: #fff;
                td {
                    padding: 3px 0;
                    &.full-area {
                        background: $grey-color !important;
                    }
                    &:nth-child(2n+1) {
                        background-color: $yellow-color;
                        color: $grey-color;
                    }
                    &:nth-child(2n) {
                        color: $html-color;
                    }
                }
                &.full-area {
                    background: $yellow-color !important;
                    td {
                        background: $grey-color !important;
                        color: #fff;
                    }
                }
            }
        }
    }
    .list-three {
        table {
            tr {
                td {
                    &:last-child {
                        width: 90px;
                    }
                }
            }
        } 
    }
}

.team-point-table {
    padding-bottom: 100px;
    h3 {
        font-size: 24px;
        font-family: 'Poppins', sans-serif;
        text-transform: uppercase;
    }
    .tab-content {
        table {
            border-left: 1px solid #e1e1e1;
            border-right: 1px solid #e1e1e1;
            .team-name {
                width: 150px;
            }
            tr {
                td {
                    padding: 8px 0 8px 10px;
                    width: 120px;
                    border-bottom: 1px solid #e1e1e1;
                    color: $html-color;
                    &:first-child {
                        padding-left: 15px;
                        width: 45px;
                    }
                }
                &:hover {
                    background-color: $yellow-color;
                }
                &:first-child {
                    background-color: #000;
                    td {
                        padding: 8px 0 8px 10px;
                        border: none;
                        color: $yellow-color;
                    }
                }
            }
        }
    }
}

/* --------------------------------------
    Championship page page css Start Here
-------------------------------------- */
.champion-section-area {
    .club-sidebar-top {
        .club-name {
            display: block;
            h2 {
                &.title-bg {
                    margin-bottom: 5px;
                    margin-top: 24px;
                    font-size: 24px;
                    padding-left: 3px;
                    &:after {
                        display: none;
                    }
                    &:before {
                        display: none;
                    }
                }
            }
        }
    }
    .rs-count {
        .rs-counter-list {
            text-align: center;
            h2 {
                margin: 0 auto 15px;
                font-size: 24px;
                width: 120px;
                height: 120px;
                border-top: 4px solid #fbc02d;
                border-bottom: 4px solid #ddd;
                line-height: 120px;
                text-align: center;
                border-radius: 50%;
            }
            h3 {
                font-size: 18px;
                font-weight: 500;
                margin: 0;
            }
        }
    }
    .point-menu {
        margin-top: 50px;
        li {
            display: inline-block;
            padding: 7px 45px 20px 0;
            a {
                color: $html-color;
                font-weight: 600;
                border: none;
                text-transform: uppercase;
                border: 1px solid $yellow-color;
                padding: 6px 30px;
                border-radius: 2px;
                &:hover {
                    color: $yellow-color;
                    background-color: unset;
                }
                &.active {
                    color: $yellow-color !important;
                    border: 1px solid $yellow-color;
                }
            }
        }
        
    }
    .team-list {
        .list-item {
            width: 100%;
            padding-top: 10px;
            text-align: center;
            overflow: hidden;
            display: table;
            &:nth-child(odd) {
                background-color: rgba(0, 0, 0, 0.05);
            }
            &:first-child {
                margin-top: 0;
                background-color: rgba(0, 0, 0, 0.08);
                padding: 10px 0;
                .list-text {
                    padding: 0;
                }
            }
            &:hover {
                .list-text {
                    .name {
                        h4 {
                            a {
                                i {
                                    @include transform(scale(1));
                                }
                            }
                        }
                    }
                }
            }
            .list-text {
                width: 100%;
                display: table-cell;
                padding: 0;
                vertical-align: middle;
                .name {
                    width: 60%;
                    float: left;
                    h4 {
                        font-size: 18px;
                        font-weight: 500;
                        text-align: left;
                        margin: 0 0 10px;
                        a {
                            color: $grey-color;
                            &:hover {
                                color: $yellow-color;
                            }
                            i {
                                @include transform(scale(0.8));
                                @include transition(0.3s);
                                color: #000;
                                margin-left: 3px;
                            }
                        }
                    }
                }
                .designation {
                    width: 20%;
                    float: left;
                    text-align: center;
                }
            }
            h2 {
                font-size: 15px;
                font-weight: bold;
                margin-bottom: 0;
            }
        }
    }
}

/* ----------------------------------
    News Section Start Here 
-------------------------------------*/
.rs-blog {
    .default-pagination {
        padding-top: 0;
    }
    .single-blog-slide {
        height: 450px;
        position: relative;
        overflow: hidden;
        margin-bottom: 30px;
        &:hover {
            .images {
                overflow: hidden;
                img {
                    @include transform(scale(1.1));
                }
            }
            .blog-details {
                .read-more {
                    a {
                        background: $yellow-color;
                    }
                }
            }
        }
        .images {
            text-align: center;
            overflow: hidden;
            &:hover {
                img {
                    @include transform(scale(1.1));
                }
            }
            img {
                height: 190px;
                @include transform(scale(1));
                @include transition(0.6s);
            }
        }
        .blog-details {
            margin: 10px 0 0;
            span {
                &.date {
                    font-weight: 500;
                }
            }
            h3 {
                margin-bottom: 10px;
                line-height: 24px;
                a {
                    color: #111111;
                    @include transition(all 0.4s ease 0s);
                    font-size: 18px;
                    font-weight: 500;
                    font-family: 'Poppins', sans-serif;
                    &:hover {
                        color: $yellow-color;
                    }
                }
            }
            p {
                margin-bottom: 15px;
                color: #111111;
            }
            .read-more {
                display: block;
                position: absolute;
                bottom: 0;
                a {
                    display: inline-block;
                    color: #111111;
                    @include transition(all 0.4s ease 0s);
                    font-weight: 400;
                    background: transparent;
                    font-family: 'Poppins', sans-serif;
                    border: 2px solid $yellow-color;
                    padding: 5px 16px;
                    border-radius: 2px;
                }
            }
        }
    }
}

/* ------------------------------------
    News Details Start here
---------------------------------------*/
.single-blog-details {
    .single-image {
        margin-bottom: 25px;
        overflow: hidden;
        img {
            filter: grayscale(0%);
            @include transition(all 0.8s ease-in-out 0s);
            &:hover {
                filter: grayscale(100%);
                @include transform(scale(1.1));
            }
        }
    }
    h3 {
        font-size: 18px;
        color: #111111;
        margin: 20px 0;
        padding: 0;
        line-height: 25px;
        font-weight: 500;
    }
    p {
        margin-top: 5px;
    }
    .sidebar-area {
        span {
            &.title {
                font-size: 18px;
                color: #111111;
                font-weight: 500;
                text-transform: uppercase;
                display: block;
                font-family: 'Poppins', sans-serif;
                background: $yellow-color;
                padding: 12px 20px;
                margin: 0 0 25px;
            }
        }
        .recent-post-area {
            ul {
                li {
                    margin: 0 0 20px;
                    padding: 0 0 20px;
                    border-bottom: 1px solid #ddd;
                    img {
                        @include transition(all 0.4s ease 0s);
                        cursor: pointer;
                    }
                    h4 {
                        margin: 0;
                        line-height: 20px;
                        a {
                            font-size: 15px;
                            font-weight: 500;
                            color: #111111;
                            font-family: 'Poppins', sans-serif;
                        }
                    }
                    span {
                        font-size: 12px;
                        margin-right: 12px;
                        i {
                            font-size: 12px;
                            margin-right: 4px;
                        }
                    }
                    &:last-child {
                        border-bottom: none;
                    }
                    &:hover {
                        a {
                            color: $yellow-color;
                        }
                        img {
                            opacity: .5;
                        }
                    }
                }
            }
        }
    }
}

/* ------------------------------------
    Contact Page Section Start Here
---------------------------------------*/
.contact-page-section {
    #mapBcsm {
        height: 490px;
    }
    .map-text {
        padding-bottom: 22px;
        h3 {
            font-size: 18px;
            font-family: 'Poppins', sans-serif;
            margin: 0;
            padding: 50px 0 10px;
            font-weight: 500;
        }
        p {
            font-family: 'Poppins', sans-serif;
            margin: 0;
            padding: 0;
        }
    }
    .contact-address-section {
        background-color: #3e3e3e;
        text-align: center;
        margin: 0 0 50px 0;
        .contact-pd {
            padding: 0 10px;
        }
        i {
            color: $yellow-color;
            font-size: 36px;
            margin: 0;
            padding: 0;
        }
        h4 {
            font-family: 'Poppins', sans-serif;
            font-size: 18px;
            font-weight: 500;
            text-transform: uppercase;
            color: #ffffff;
            margin: 0;
            padding: 15px 0;
        }
        p {
            color: #cccccc;
            margin: 0;
            padding: 0;
        }
        .contact-address {
            border: 1px solid #fbc02d;
            margin: 25px 0 25px 10px;
            padding: 30px 0;
        }
        .contact-phone {
            border: 1px solid $yellow-color;
            margin: 25px 0;
            padding: 30px 0;
            a {
                display: block;
                color: #cccccc;
                &:hover {
                    color: $yellow-color;
                }
            }
        }
        .contact-email {
            border: 1px solid $yellow-color;
            margin: 25px 10px 25px 0;
            padding: 30px 0;
            p {
                &:hover {
                    color: $yellow-color;
                }
            }
        }
    }
    
}

/* ------------------------------------
    Clicent Logo Section
---------------------------------------*/
.clicent-logo-section {
    padding-bottom: 50px;
    position: relative;
    background: url(../images/upcoming/background.jpg);
    background-size: cover;
    .single-logo {
        margin-bottom: 30px;
        text-align: center;
        background: rgba(0, 0, 0, 0.4);
        @include transition(0.4s);
        &:hover {
            background: #000000;
        }
        a {
            display: block;
        }
    }
    .owl-stage {
        display: flex;
        .owl-item {
            margin-top: auto;
            margin-bottom: auto;
        }
    }
}

/* ------------------------------------
    Footer Section Start here 
---------------------------------------*/
.footer-section {
    background-color: $grey-color;
    color: #afb0b2;
    .footer-top {
        padding: 100px 0 95px;
        .footer-title {
            margin-bottom: 35px;
            padding-bottom: 12px;
            border-bottom: 2px solid #999;
            color: #fff;
            font-size: 18px;
            position: relative;
            font-weight: 500;
            text-transform: capitalize;
            &:after {
                content: '';
                position: absolute;
                left: 0;
                bottom: -2px;
                height: 2px;
                width: 50px;
                background-color: $yellow-color;
            }
            .about-widget {
                p {
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
        .recent-post-widget {
            .post-item {
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                -ms-flex-align: center;
                -webkit-align-items: center;
                align-items: center;
                .post-date {
                    width: 70px;
                    height: 65px;
                    flex: 0 0 70px;
                    text-align: center;
                    background-color: $yellow-color;
                    color: $grey-color;
                    margin-right: 15px;
                    &>span {
                        display: block;
                        &:first-child {
                            margin-top: 6px;
                        }
                        &:last-child {
                            font-size: 13px;
                        }
                    }
                }
                .post-title {
                    font-size: 15px;
                    line-height: 24px;
                    margin-bottom: 0;
                    a {
                        color: #afb0b2;
                        font-weight: 400;
                        &:hover {
                            color: #fff;
                        }
                        &:focus {
                            color: #fff;
                        }
                    }
                }
                .post-category {
                    font-size: 13px;
                }
                &+.post-item {
                    margin-top: 15px;
                    padding-top: 15px;
                    border-top: 1px solid rgba(102, 102, 102, 0.5);
                }
            }
        }
        .sitemap-widget {
            overflow: hidden;
            li {
                a {
                    color: #afb0b2;
                    display: block;
                    border-bottom: 1px solid rgba(102, 102, 102, 0.5);
                    position: relative;
                    padding: 4px 0 4px 14px;
                    &:hover {
                        color: #fff;
                    }
                    &:focus {
                        color: #fff;
                    }
                    &:after {
                        content: "\f105";
                        font-family: "FontAwesome";
                        font-size: 14px;
                        left: 0;
                        position: absolute;
                        top: 4px;
                        color: #afb0b2;
                        font-weight: 400;
                    }
                }
                &:last-child {
                    a {
                        border-bottom: 0;
                    }
                }
            }
        }
        .footer-subscribe {
            position: relative;
            input {
                font-size: 15px;
                padding: 8px 15px;
                border: none;
                border-radius: 0;
                height: 45px;
                display: block;
                line-height: 1.428571429;
                color: $grey-color;
                background-color: #fff;
                outline: none;
                width: 100%;
                font-weight: 400;
                margin-bottom: 15px;
            }
            button[type="submit"] {
                @extend input;
                margin-bottom: 0;
                background: $yellow-color;
                padding: 0;
                border: 2px solid $yellow-color;
                @include transition(0.4s);
                text-transform: uppercase;
                font-weight: 600;
                &:hover {
                    background: transparent;
                    color: #ffffff;
                }
            }
        }
    }
    .footer-bottom {
        padding: 20px 0;
        border-top: 1px solid #303030;
        font-size: 14px;
        .copyright {
            p {
                margin-bottom: 0;
                a {
                    color: $yellow-color;
                    &:hover {
                        color: #afb0b2;
                    }
                    &:focus {
                        color: #afb0b2;
                    }
                }
            }
        }
        .footer-bottom-share {
            display: inline-block;
            ul {
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                -ms-flex-wrap: wrap;
                -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
                li {
                    display: inline-block;
                    &+li {
                        margin-left: 10px;
                    }
                    a {
                        font-size: 15px;
                        display: block;
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        line-height: 32px;
                        text-align: center;
                        color: #fff;
                        @include transition(all 0.4s ease 0s);
                        background: #383838;
                        &:hover {
                            color: $yellow-color;
                        }
                    }
                }
            }
        }
    }
}

/* ------------------------------------
    Gallery Section Area
---------------------------------------*/
.gallery-section-area {
    .grid {
        margin: 0 auto;
        .single-gallery {
            margin: 0 0 30px;
            text-align: center;
            position: relative;
            overflow: hidden;
            &:after {
                content: "";
                width: 0;
                height: 0;
                background: #000;
                opacity: 0.6;
                position: absolute;
                top: -139px;
                left: 0;
                @include transform(skewY(140deg));
                @include transition(0.4s);
            }
            &:before {
                content: "";
                width: 0;
                height: 0;
                background: #000;
                opacity: 0.6;
                position: absolute;
                bottom: -139px;
                right: 0;
                @include transform(skewY(140deg));
                @include transition(0.4s);
            }
            &:hover {
                .bottom-icons {
                    opacity: 1;
                    visibility: visible;
                }
                .heading-conent {
                    opacity: 1;
                    visibility: visible;
                }
                &:after {
                    width: 240px;
                    height: 240px;
                }
                &:before {
                    width: 240px;
                    height: 240px;
                }
            }
            img {
                padding: 0px;
                max-height: 260px;
            }
            .heading-conent {
                opacity: 0;
                visibility: hidden;
                position: absolute;
                top: 30px;
                left: 20px;
                z-index: 11;
                a {
                    &.show {
                       color: #fff;
                       i {
                          color: #fff; 
                       }
                       &:hover {
                           color: $yellow-color;
                           i {
                               color: $yellow-color;
                           }
                       }
                    }
                }
                @include transition(0.5s);
                h4 {
                    color: #ffffff;
                    margin: 0 0 6px;
                    font-size: 18px;
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                }
                ul {
                    text-align: left;
                    li {
                        display: inline-block;
                        i {
                            color: #fbc02d;
                        }
                    }
                }
            }
            .bottom-icons {
                opacity: 0;
                visibility: hidden;
                position: absolute;
                bottom: 30px;
                right: 20px;
                z-index: 11;
                @include transition(0.5s);
                ul {
                    li {
                        display: inline-block;
                        i {
                            color: #fbc02d;
                            padding-left: 15px;
                        }
                    }
                }
            }
        }
    }
    #gallery-items {
        @extend .grid;
        
    }
}
.gallery-section-page2 {
    padding-bottom: 70px;
    .single-gallery {
        position: relative;
        overflow: hidden;
        margin-bottom: 30px;
        cursor: pointer;
        text-align: center;
        img {
            max-height: 360px; 
        }
        &:after {
            content: "";
            width: 100%;
            height: 100%;
            background: #fbc02d;
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            overflow: hidden;
            opacity: 0.8;
            transform: scale(0);
            opacity: 0;
            visibility: hidden;
            @include transition(0.4s);
        }
        &:hover {
            .popup-icon {
                opacity: 1;
                visibility: visible;
            }
            &:after {
                opacity: 0.8;
                visibility: visible;
                @include transform(scale(1));
            }
        }
        .popup-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            text-align: center;
            @include transform(translate(-50%, -50%));
            z-index: 11;
            opacity: 0;
            visibility: hidden;
            transition: 0.3s;
            a {
                width: 50px;
                height: 50px;
                line-height: 48px;
                text-align: center;
                border: 2px solid #111111;
                font-size: 20px;
                display: inline-block;
                margin: 0 8px;
                border-radius: 50%;
                color: #111111;
                &:hover {
                    color: #ffffff;
                }
            }
        }
    }
}

/* ------------------------------------
    User Single Section
---------------------------------------*/
.rs-team-single-section {
    .rs-team-single-text {
        h3 {
            font-size: 24px;
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
        }
        .single-details {
            padding: 20px;
            margin: 0;
            background-color: #fbc02d;
            .single-team-info {
                padding: 0;
                table {
                    tr {
                        td {
                            h3 {
                                font-size: 15px;
                                font-family: 'Poppins', sans-serif;
                                font-weight: 500;
                                margin: 0 0 15px;
                            }
                            h4 {
                                font-size: 15px;
                                font-family: 'Poppins', sans-serif;
                                font-weight: 400;
                                color: #505050;
                                margin: 0 0 15px;
                            }
                        }
                        &:last-child {
                            h3 {
                                margin: 0;
                            }
                            h4 {
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    .team-single-comment {
        a {
            &.btn-warning {
                float: right;
                font-size: 15px;
                text-transform: uppercase;
                font-family: 'Poppins', sans-serif;
                color: #000;
                background-color: #fbc02d;
                margin-top: 30px;
                border: none;
                width: 170px;
                text-align: center;
                line-height: 45px;
                @include transition(0.4s);
                &:hover {
                    background: #111111;
                    color: #fff;
                }
            }
        }
    }
    button {
        &.btn-bcsm {
            float: right;
            font-size: 15px;
            text-transform: uppercase;
            font-family: 'Poppins', sans-serif;
            color: #000;
            margin-top: 30px;
            border: none;
            width: 170px;
            text-align: center;
            line-height: 45px;
            @include transition(0.4s);
            &:hover {
                background: #111111;
                color: #fff;
            }
        }
    }
}


/* -----------------------------------
    ADMIN CSS
----------------------------------- */
#admin {
    font-size: .875rem;
    .alert {
        margin-bottom: 15px !important;
    }
    .navbar-brand {
        padding-top: .75rem;
        padding-bottom: .75rem;
        font-size: 1rem;
        background-color: rgba(0, 0, 0, .25);
        box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
    }
    .sidebar {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 100; /* Behind the navbar */
        padding: 48px 0 0; /* Height of navbar */
        box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
        .sidebar-sticky {
            position: relative;
            top: 0;
            height: calc(100vh - 48px);
            padding-top: .5rem;
            overflow-x: hidden;
            overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
            .nav-link {
                font-weight: 500;
                color: #333;
                .fa {
                    margin-right: 4px;
                    color: #999;
                    width: 16px;
                    height: 16px;
                    vertical-align: text-bottom;
                }
                &.active {
                    color: #007bff;
                    .fa {
                        color: inherit;
                    }
                }
                &:hover {
                    .fa {
                        color: inherit;
                    }
                }
            }
        }
    }
    [role="main"] {
        padding-top: 48px; /* Space for fixed navbar */
        .table-responsive {
            table {
                thead {
                    tr {
                        th {
                            a {
                                color: #fff;
                                &:hover {
                                    color: $yellow-color;
                                }
                                &.asc {
                                    &:after {
                                        content: "\f15d";
                                        font-family:FontAwesome;
                                        margin-left: 5px;
                                    }
                                }
                                &.desc {
                                    &:after {
                                        content: "\f15e";
                                        font-family:FontAwesome;
                                        margin-left: 5px;
                                    }
                                }
                                &.sortable {
                                    &:after {
                                        content: "\f0dc";
                                        font-family:FontAwesome;
                                        margin-left: 5px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .marginCheckboxLabel {
        label {
            margin-left: 5px;
            margin-right: 10px;
        }
    }
}

/* -----------------------------------
    SLICK-SLIDER
----------------------------------- */
.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    .slick-track {
        position: relative;
        top: 0;
        left: 0;
        display: block;
        margin-left: auto;
        margin-right: auto;
        @include transform(translate3d(0, 0, 0));
        &:before {
            display: table;
            content: '';
        }
        &:after {
            display: table;
            content: '';
            clear: both;
        }
    }
    .slick-list {
        position: relative;
        display: block;
        overflow: hidden;
        margin: 0;
        padding: 0;
        @include transform(translate3d(0, 0, 0));
        &:focus {
            outline: none;
        }
        &.dragging {
            cursor: pointer;
            cursor: hand;
        }
    }
    .slick-slide {
        display: none;
        float: left;
        height: 100%;
        min-height: 1px;
        img {
            display: block;
        }
        &.slick-loading {
            img {
                display: none;
            }
        }
        &.dragging {
            img {
                pointer-events: none;
            }
        }
    }
    [dir='rtl'] {
        .slick-slide {
            float: right;
        }
    }
}
.slick-initialized {
    .slick-slide {
        display: block;
    }
}
.slick-loading {
    .slick-slide {
        visibility: hidden;
    }
    .slick-track {
        visibility: hidden;
    }
}
.slick-vertical {
    .slick-slide {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}

/* -----------------------------------
    PAGINATION CSS
----------------------------------- */
.bcsm-pagination {
    padding-top: 30px;
    ul {
        text-align: center;
        overflow: hidden;
        margin: 0 auto;
        box-shadow: 0px 0px 5px 0px;
        li {
            display: inline-block;
            padding: 10px 0;
            &.active {
                a {
                    background-color: #fbc02d !important;
                    border-color: #fbc02d !important;
                    color: #111111;
                }
                span {
                    background-color: #fbc02d !important;
                    border-color: #fbc02d !important;
                    color: #111111;
                }
            }
            &:first-child {
                a {
                    width: 125px;
                }
                span {
                    width: 125px;
                }
            }
            &:last-child {
                a {
                    width: 125px;
                }
                span {
                    width: 125px;
                }
            }
            a {
                display: block;
                line-height: 30px;
                background: transparent;
                border: none;
                color: #505050;
                font-size: 18px;
                text-decoration: none;
                @include transition(all 0.4s ease 0s);
                text-align: center;
                font-weight: 500;
                &:hover {
                    background: #fbc02d;
                    color: #111111;
                }
                &:focus {
                    box-shadow: 0 0 0 0.2rem rgba(251, 192, 45, 0.25);
                }
            }
            span {
                display: block;
                line-height: 30px;
                background: transparent;
                border: none;
                color: #505050;
                font-size: 18px;
                text-decoration: none;
                @include transition(all 0.4s ease 0s);
                text-align: center;
                font-weight: 500;
                &:hover {
                    background: #fbc02d;
                    color: #111111;
                }
                &:focus {
                    box-shadow: 0 0 0 0.2rem rgba(251, 192, 45, 0.25);
                }
            }
        }
    }
}

/* -----------------------------------
    MENU CSS
----------------------------------- */
.rs-menu {
    float: left;
    clear: both;
    width: 100%;
    font-size: 0;
    z-index: 9999;
    ul {
        border: 0;
        margin: 0;
        padding: 0;
        text-align: right;
        ul {
            position: absolute;
            top: 100%;
            width: 100%;
            display: none;
            z-index: 999;
            &.visible {
                display:block;
                z-index: 17;
            }
            li {
                border-bottom: 1px solid #ececec;
                &:last-child {
                    border-bottom: none;
                }
            }
            a {
                font-size: 14px;	
                text-align: left;
            }
        }
    }
    li {
        border: 0;
        margin: 0;
        padding: 0;
        display: block;
        vertical-align: top;
        position: relative;
        font-size: 14px;
        i {
            margin-right:10px;
            font-size:16px;
            @include transform(translate3d(0, 0, 0));
        }
    }
    p {
        border: 0;
        margin: 0;
        padding: 0;
    }
    a {
        border: 0;
        margin: 0;
        padding: 0;
        font-weight: 400;
        display: block;
        padding: 16px;
        color:#4a4a4a;
        -webkit-tap-highlight-color:rgba(0,0,0,0);
    }
    div {
        border: 0;
        margin: 0;
        padding: 0;
    }
    i {
        border: 0;
        margin: 0;
        padding: 0;
    }
    .sub-menu {
        @include box-shadow(0px 4px 13px -3px #232323);
        li {
            a {
                padding: 10px 14px;
            }
        }
    }
    .nav-menu {
        position: relative;
        ul {
            @include transition(all .3s ease-in-out);
        }
        &>li {
            &>a {
                color:#fff;
                font-size: 16px;
                text-transform: capitalize;
                font-weight: 400;
            }
        }
        &>.menu-item-has-children {
            &>a {
                span {
                    &:after {
                        content:"\f107";
                        font-family:FontAwesome;
                        float:right;
                        margin:2px 0 0 5px;
                    }
                }
            }
        }
    }
}

/*-----------------------------------------------------------------------------------*/
/*  MEDIA QUERIES
/*-----------------------------------------------------------------------------------*/

@media only screen and (max-width: 1440px) {        
    .slider-main {
        .dsc {
            .slider-image {
                img {
                    max-width: 70% !important; 
                }
            }
        }
    }
}

@media only screen and (max-width: 1300px) {
    .slider-main {
        .dsc {
            .slider-image {
                img {
                    max-width: 60% !important; 
                }
            }
        }
    } 
}

@media only screen and (max-width: 1199px) {
    .header-middle-area {
        .rs-menu {
            &>ul {
                &>li {
                    &>a {
                        padding: 28px 10px;
                    }
                }
            }
            ul {
                ul {
                    a {
                        width: 200px;
                    }
                }
            }
        }
    }
    .slider-main {
        .dsc {
            h1 {
                font-size: 45px;
                line-height: 58px;
            }
            h2 {
                font-size: 45px;
                line-height: 58px;
            }
        }
    }
    .rs-blog {
        .single-blog-slide {
            min-height: 470px;
            .blog-details {
                h3 {
                    line-height: 18px;
                    a {
                        font-size: 15px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1080px) {	
    .nav-menu {
        &>li {
            &>a {
                i {
                    display:none;
                }
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .rs-menu-toggle {
        display: none;
    }
    
    .rs-menu {
        height: auto !important;
        ul {
            ul {
                opacity: 0;
		display: block !important;
		visibility: hidden;
                @include transform(translateY(10px));
                width: auto !important;
                &.visible {
                    visibility: hidden;
                    opacity:0;
                }
                ul {
                    top:-4px !important;
                }
                li {
                    &>ul {
                        left:100%;
                    }
                }
                a {
                    width:240px;
                }
            }
        }
        li {
            display:inline-block !important;
            &:active {
                &>ul {
                    opacity: 1;
                    visibility:visible;		
                    @include transform(translateY(0px));
                    display:block;
                    z-index: 17;
                }
            }
            &:focus {
                &>ul {
                    opacity: 1;
                    visibility:visible;		
                    @include transform(translateY(0px));
                    display:block;
                    z-index: 17;
                }
            }
            &:hover {
                &>ul {
                    opacity: 1;
                    visibility:visible;		
                    @include transform(translateY(0px));
                    display:block;
                    z-index: 17;
                }
            }
        }
        h1 {
            font-size: 3.2rem;
            line-height: 1.2em;
        }
        h2 {
            font-size: 2.5rem;
            line-height: 1.2em;
        }
        h3 {
            font-size: 2rem;
        }
        h4 {
            font-size: 1rem;
        }
        .nav-menu {
            &>.menu-item-has-children {
                &>a {
                    &:after {
                        content:"\f107";
                        font-family:FontAwesome;
                        float:right;
                        margin:2px 0 0 5px;
                    }
                }
            }
            ul {
                .menu-item-has-children {
                    &>a {
                        &:after {
                            content:"\f105";
                            font-family:FontAwesome;
                            float:right;
                        }
                    }
                }
            }
            div {
                &.sub-menu-close {
                    display: none;
                }
            }
            .menu-item-has-children {
                &>span {
                    display: none;
                }
            }
        }
    }
}

@media only screen and (min-width:992px) and (max-width: 1199px) {
    .latest-news-slider {
        .slick-arrow {
            right: -90px;
            &.slick-next {
                right: -135px;
            }
        }
    }
    
    .today-match-teams {
        .today-score {
            .today-match-team {
                display: block;
                margin: 0 auto;
                width: 100%;
            }
            .today-final-score {
                display: block;
                margin: 30px auto;
                width: 100%;
                h4 {
                    margin: 10px;
                }
            }
        }
        .today-results-table {
            tr {
                td {
                    padding: 7px 12px;
                }
            }
        }
    }
}

@media only screen and (min-width:768px) and (max-width: 991px) {
    .upcoming-section {
        span {
            font-size: 14px;
        }
    }
}

@media only screen and (max-width: 991px) { 
    .sec-spacer{
        padding: 80px 0;
    }
    .upcoming-section {
        padding: 72px 0 77px;
    }
    .rs-breadcrumbs {
        img {
            min-height: 200px;
        }
    }
    .champion-section-area {
        .rs-count {
            .rs-counter-list {
                margin-bottom: 40px;
            }
        }
        .row {
            &.pb-50 {
                padding-bottom: 0;
            }
        }
    }
    .single-blog-details {
        padding-bottom: 70px;
    }
    .header-middle-area {
        background: #111 !important;
        .rs-menu {
            padding-right: 0;
            &.rs-menu-close {
                visibility: hidden;
            }
            visibility: visible;
            &>ul {
                &>li {
                    &>a {
                        color: #ffffff !important;
                        padding: 16px 18px !important;
                        &:hover {
                            color: #fbc02d !important;
                        }
                    }
                    &.active {
                        &>a {
                            color: #fbc02d !important;
                        }
                    }
                }
            }
        }
        .container {
            width: 100%;
            .mobile-menu {
                padding-left: 0;
                padding-right: 0;
            }
        }
        .logo {
            position: static;
            height: auto;
            text-align: center;
            padding-bottom: 10px;
        }
    }
    .today-match-teams {
        .today-score {
            .today-match-team {
                float: none;
                display: inline-block;
            }
            .today-final-score {
                float: none;
                display: inline-block;
            }
        }
    }
    .footer-section {
        .footer-top {
            padding: 80px 0;
            [class*="col-"] {
                &+[class*="col-"] {
                    margin-top: 40px;
                }
            }
        }
        .about-widget {
            p {
                &:first-child {
                    margin-bottom: 12px;
                }
            }
        }
    }
    .slider-main {
        .dsc {
            .slider-text {
                margin-top: 30px;
                h1 {
                    font-size: 35px;
                }
                .CountDownTimer {
                    margin: 20px auto;
                    max-width: 400px;
                    .time_circles {
                        &>div {
                            span {
                                font-size: 26px !important;
                            }
                            h4 {
                                font-size: 10px !important;
                            }
                        }
                    }
                }
            }
            h1 {
                line-height: 44px;
                font-size: 35px;
            }
            h2 {
                line-height: 44px;
                font-size: 35px;
            }
            .btn-slider {
                .btn1 {
                    padding: 5px 35px;
                }
                .btn2 {
                    padding: 5px 35px;
                }
            }
        }
        .owl-dots {
            bottom: 20px;
        }
    }
    .upcoming-section {
        h2 {
            font-size: 35px;
        }
    }
    .sidebar-area {
        .recent-post-area {
            margin-top: 30px;
            ul {
                li {
                    &:last-child {
                        padding-bottom: 8px;
                    }
                }
            }
        }
        &.right-side-area {
            padding-top: 45px;
        }
    }
    .contact-page-section {
        .contact-address-section {
            .contact-email {
                margin: 25px 10px;
            }
            .contact-phone {
                margin: 25px 10px;
            }
            .contact-address {
                margin: 25px 10px;
            }
        }
    }
    .rs-blog {
        .single-blog-slide {
            min-height: 510px;
        }
    }
    .rs-menu {
        .nav-menu {
            &>li {
                &>a {
                    border-bottom:1px solid #131313;
                    color: #fff !important;
                    text-align: left;
                }
                &:last-child {
                    &>a {
                        border-bottom:none;
                    }
                }
            }
            &>.menu-item-has-children {
                &>span {
                    &.rs-menu-parent {
                        display: block;
                        position: absolute;
                        right: 0;
                        top: 0;
                        padding: 20px;	
                        cursor:pointer;
                        line-height: 15px;
                        background: #181818;
                        border-left: 1px solid #131313;		
                        -webkit-tap-highlight-color: rgba(0,0,0,0);
                        &:hover {
                            color: #fff;
                        }
                    }
                }
                .sub-menu {
                    .menu-item-has-children {
                        span {
                            &.rs-menu-parent {
                                display: block;
                                position: absolute;
                                right: 0;
                                top: 0;
                                padding: 20px;	
                                cursor:pointer;
                                line-height: 15px;
                                border-left: 1px solid rgba(0, 0, 0, .05);		
                                -webkit-tap-highlight-color: rgba(0,0,0,0); 
                            }
                        }
                    }
                }
            }
            span {
                &.rs-menu-parent {
                    i {
                        margin: 0;
                    }
                }
            }
            div {
                &.sub-menu-close {
                    float: left;
                    width: 100%;
                    padding: 16px;
                    cursor: pointer;
                    background: #f6f3ed;
                    color: #000;
                    font-size: 14px;
                    text-align: center;
                    i {
                        font-size:16px;
                        margin-right: 5px;
                    }
                }
            }
            .sub-menu {
                @include box-shadow(0 0 20px rgba(0,0,0,.15));
            }
        }
    }
    .rs-menu-close {
        height: 0;		
    	overflow: hidden!important;
    }
    .rs-menu-toggle {
        padding: 16px;
        display: block;
        background: #222;
        border-left: 1px solid #222;
        position: relative;
        cursor:pointer;		
        color: #eee !important;
        font-size: 15px;
        text-transform: uppercase;
        -webkit-tap-highlight-color:rgba(0,0,0,0);
        i {
            margin-right:10px; 
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .container {
        max-width: 100%;
    }
    .rs-blog {
        .single-blog-slide {
            min-height: 310px;
        }
    }
}

@media only screen and (max-width: 767px) {
    .container {
        width: 100%;
        padding-right: 25px;
        padding-left: 25px;
    }
    .rs-blog {
        .single-blog-slide {
            min-height: 410px;
        }
    }
    .slider-main {
        &.slider-section4 {
            .item {
                img {
                    min-height: 300px;
                }
            }
        }
        .slider-text {
            .CountDownTimer {
                .time_circles {
                    &>div {
                        span {
                            font-size: 18px !important;
                        }
                        h4 {
                            font-size: 7px !important;
                        }
                    }
                }
            }
        }
        .owl-dots {
            display: none !important;
        }
        .dsc {
            .btn-slider {
                a {
                    padding: 5px 30px;
                }
                .btn1 {
                    padding: 5px 30px;
                }
            }
            h1 {
                font-size: 25px;
                line-height: 30px;
            }
            h2 {
                font-size: 25px;
                line-height: 30px;
            }
        }
    }
    .header-top-area {
        .social-media-area {
            ul {
                text-align: center;
            }
        }
        .header-top-left {
            ul {
                text-align: center;
                li {
                    float: none;
                    display: inline-block;
                    &:last-child {
                        position: relative;
                    }
                    ul {
                        right: 0;
                    }
                }
            }
        }
    }
    .header-middle-area {
        .container {
            padding-right: 15px;
            padding-left: 15px;
        }
    }
    .champion-section-area {
        .club-logo {
            margin-bottom: 30px;
            text-align: center;
        }
    }
    .club-sidebar-top {
        .club-name {
            h2 {
                &.title-bg {
                    margin-top: 20px;
                }
            }
        }
    }
    .all-news-area {
        .latest-news-slider {
            .slick-arrow {
                display: none !important;
            }
        }
        .latest-news-nav {
            .slick-slide {
                width: 33.33% !important;
            }
            &.slick-slider {
                margin-top: 20px;
            }
        }
    }
    .upcoming-section {
        .vs {
            padding: 15px 0 10px;
        }
        .first {
            margin-bottom: 30px;
        }
        .owl-nav {
            margin-top: 30px;
            .owl-prev {
                display: inline-block;
                margin: 0 15px;
            }
            .owl-next {
                display: inline-block;
                margin: 0 15px;
            }
        }
        .last {
            margin-top: 30px;
        }
        h2 {
            font-size: 25px;
            line-height: 30px;
        }
    }
    .footer-bottom {
        .ft-bottom-right {
            padding-top: 10px;
            text-align: center;
        }
        .copyright {
            text-align: center;
        }
    }
    .team-list {
        .list-item {
            .list-text {
                padding: 20px 0 0;
            }
        }
    }
    .contact-page-section {
        #mapBcsm {
            height: 380px;
        }
    }
}

@media only screen and (max-width: 590px) {
    .slider-main {
        .dsc {
            .btn-slider {
                margin-top: 20px;
                margin-bottom: 5px;
            }
            .slider-text {
                .CountDownTimer {
                    margin: 10px auto 5px;
                    max-width: 300px;
                }
                h1 {
                    font-size: 20px;
                }
            }
        }
    }
    .logo {
        img {
            max-height: 120px;
        }
    }
    .title-bg {
        font-size: 20px;
        margin-bottom: 35px;
    }
    .champion-section-area {
        .point-menu {
            text-align: center;
            li {
                padding: 0 4px;
                line-height: 50px;
            }
        }
    }
    .rs-breadcrumbs {
        .page-title {
            font-size: 26px;
        }
    }
    .team-page {
        .list-three {
            table {
                tr {
                    td {
                        &:last-child {
                            width: 75px;
                        }
                    }
                }
            }
        }
    }
    .team-point-table {
        .tab-content {
            table {
                .team-name {
                    width: 200px;
                }
            }
        }
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .container {
        max-width: 100%;
    }
}

@media only screen and (min-width: 480px) {
    
}

@media screen and (min-width: 500px) {
    .rs-menu {
        h1 {
            font-size: 3.8rem;
            line-height: 1.2em;
        }
        h2 {
            font-size: 2rem;
            line-height: 1.2em;
        }
        h3 {
            font-size: 3.3rem;
        }
        h4 {
            font-size: 2.8rem;
        }
    }
}

@media only screen and (max-width: 480px) {
    .rs-blog {
        .single-blog-slide {
            min-height: auto;
        }
    }
    .slider-main {
        .owl-carousel {
            .owl-item {
                img {
                    min-height: 200px;
                }
            }
        }
        .dsc {
            .btn-slider {
                a {
                    padding: 5px 12px;
                    margin: 0 5px;
                    font-size: 12px;
                }
                .btn1 {
                    padding: 5px 12px;
                    margin: 0 5px;
                    font-size: 12px;
                }
                .btn2 {
                    padding: 5px 12px;
                }
            }
            h2 {
                font-size: 20px;
                line-height: 25px;
            }
            h1 {
                font-size: 20px;
                line-height: 25px;
            }
            .slider-text {
                margin-top: 5px;
                line-height: 10px;
                h1 {
                    font-size: 16px;
                }
            }
            .btn-slider {
                margin-top: 10px;
            }
        }
    }
    .rs-breadcrumbs {
        img {
            min-height: 150px;
        }
    }
    .header-top-area {
        .header-top-left {
            ul {
                li {
                    &:last-child {
                        margin-left: 10px;
                    }
                    a {
                        font-size: 13px;
                    }
                }
            }
        }
        .social-media-area {
            .log {
                margin-left: 0;
                a {
                    font-size: 13px;
                }
            }
            .sign {
                a {
                    font-size: 13px;
                }
            }
            ul {
                li {
                    a {
                        margin-right: 6px;
                        font-size: 13px;
                    }
                }
            }
        }
    }
    .today-match-teams {
        .today-score {
            .today-match-team {
                display: block;
                margin: 0 auto;
                width: 100%;
            }
            .today-final-score {
                display: block;
                margin: 0 auto;
                width: 100%;
                margin: 30px auto;
                h4 {
                    margin: 10px;
                }
            }
        }
        .today-results-table {
            tr {
                td {
                    padding: 7px 12px;
                    font-size: 13px;
                }
            }
        }
    }
    .contact-page-section {
        #mapBcsm {
            height: 300px;
        }
    }
    .team-page {
        .match-summary {
            table {
                tr {
                    td {
                        font-size: 11px;
                    }
                }
            }
        }
        .list-three {
            table {
                tr {
                    td {
                        &:last-child {
                            width: 90;
                        }
                    }
                }
            }
        }
    }
    .champion-section-area {
        .rs-count {
            .rs-counter-list {
                h3 {
                    font-size: 16px;
                }
            }
        }
        .point-menu {
            li {
                padding-right: 14px;
                a {
                    font-size: 13px;
                }
            }
        }
    }
    .team-list {
        .list-item {
            img {
                width: 15%;
            }
            .list-text {
                width: 85%;
                .designation {
                    width: 40%;
                }
                .name {
                    width: 50%;
                    h4 {
                        font-size: 14px;
                    }
                }
            }
        }
    }
    .team-point-table {
        .tab-content {
            table {
                border-left: 0;
                border-right: 0;
                tr {
                    border-top: 1px solid #e1e1e1;
                    border-left: 1px solid #e1e1e1;
                    border-right: 1px solid #e1e1e1;
                    margin-bottom: 25px;
                    &:first-child {
                        margin-bottom: 0;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                    td {
                        width: auto; 
                        border: 1px solid #e1e1e1;
                        font-size: 12px;
                        padding: 3px;
                        &:first-child {
                            padding-left: 0;
                            width: auto;
                        }
                    }
                }
            }
        }
    }
    .footer-bottom {
        .copyright {
            p {
                font-size: 13px;
            }
        }
    }
    .all-news-area {
        .latest-news-slider {
            .news-normal-block {
                height: 500px;
                .news-title {
                    font-size: 16px;
                }
                .news-desc {
                    p {
                        margin-bottom: 10px;
                        font-size: 13px;
                    }
                }
                .news-img {
                    img {
                        height: 200px;
                    }
                }
            }
        }
    }
}

@media screen and (min-width:0\0) {
    .nav-menu {
        .menu-item-has-children {
            a {
                padding-right: 24px;
                &:after {
                    position:static;
                    float:right;
                    margin:0 -16px 0 0
                }
            }
        }
    }
}